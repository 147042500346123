import PropTypes from 'prop-types';
import { useRef } from 'react';
import Slider from 'react-slick';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Card, Link, Stack, Button, Divider, Container, Typography } from '@mui/material';

// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { MotionInView, varFade } from '../../components/animate';
import feedback from '../../assets/feedback.png';
import dot from '../../assets/dot.svg';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: 20,
  backgroundColor: '#24263C',
  [theme.breakpoints.up('md')]: {
    padding: 20,
  },
}));

const SectionStyleTwo = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0px',
}));

function SampleNextArrow(props) {
  const { style } = props;
  return <div style={{ ...style, display: 'none' }} />;
}

function SamplePrevArrow(props) {
  const { style } = props;
  return <div style={{ ...style, display: 'none' }} />;
}

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  appendDots: (dots) => (
    <div>
      <ul style={{ margin: '0px' }}> {dots} </ul>
    </div>
  ),
  customPaging: (i) => (
    <div
      style={{
        width: '10px',
      }}
    >
      <Image
        visibleByDefault
        disabledEffect
        src={dot}
        sx={{ width: 10, height: 10 }}
        style={{ alignItems: 'center' }}
      />
    </div>
  ),
};

// ----------------------------------------------------------------------

export default function HomeTestimonly() {
  const theme = useTheme();
  const carouselRef = useRef(null);

  return (
    <RootStyle>
      <Container id="section5">
        <Box sx={{ mb: 5, textAlign: 'center' }}>
          <MotionInView variants={varFade().inDown}>
            <Typography variant="h3" sx={{ mb: 0, textAlign: 'left' }}>
              Hear about Swahilies from our future <br />
              billionaire businesses.
            </Typography>
          </MotionInView>
        </Box>

        <Container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Slider {...settings}>
            <Image visibleByDefault alt="rocket" src={feedback} disabledEffect sx={{ maxWidth: '65%' }} />
            <Image visibleByDefault alt="rocket" src={feedback} disabledEffect sx={{ maxWidth: '65%' }} />
          </Slider>
        </Container>

        {/* <Grid container spacing={5} sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Slider {...settings} ref={carouselRef}>
            <Grid item xs={12} md={4}>
              <MotionInView variants={varFade().inDown}>
                <Image disabledEffect alt="grid" src={feedback} />
              </MotionInView>
            </Grid>
            <Grid item xs={12} md={4}>
              <MotionInView variants={varFade().inDown}>
                <Image disabledEffect alt="grid" src={feedback} />
              </MotionInView>
            </Grid>
            <Grid item xs={12} md={4}>
              <MotionInView variants={varFade().inDown}>
                <Image disabledEffect alt="grid" src={feedback} />
              </MotionInView>
            </Grid>
          </Slider>
        </Grid> */}
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------
