import { m } from 'framer-motion';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
// @mui
import { styled } from '@mui/material/styles';
import { Slide } from 'react-slideshow-image';
import { Button, Box, Link, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import TextIconLabel from '../../components/TextIconLabel';
import { MotionContainer, varFade } from '../../components/animate';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 0),
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
  zIndex: 10,
  maxWidth: 850,
  margin: 'auto',
  textAlign: 'center',
  position: 'relative',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    textAlign: 'center',
  },
}));

// ----------------------------------------------------------------------

export default function HomeWelcome() {
  return (
    <RootStyle style={{ backgroundColor: '#F4F4F4' }}>
      <Container>
        <ContentStyle>
          <m.div variants={varFade().inRight}>
            <Typography
              variant="h3"
              sx={{ color: '#362E93', textAlign: 'center', fontFamily: 'Open Sans, sans-serif' }}
            >
              Do you wish to accept payments easily, faster & with low-fees?
            </Typography>
          </m.div>

          <m.div variants={varFade().inRight} style={{ paddingBottom: 10 }}>
            <Typography
              sx={{ color: 'common.black' }}
              style={{ fontSize: '0.8rem', fontWeight: 'bold', textAlign: 'center' }}
            >
              We bet you do! Click the button below if we are right 😍
            </Typography>
          </m.div>

          <m.div variants={varFade().inRight}>
            <Button
              size="small"
              variant="contained"
              component={RouterLink}
              to="/contact-us"
              endIcon={<Iconify icon={'carbon:arrow-right'} width={13} height={13} />}
            >
              Contact Swahilies team
            </Button>
          </m.div>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
