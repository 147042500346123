import { Button, Box, Link, Container, Typography, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE, PATH_WEB } from '../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';
import { HomeAdvertisement } from '../../sections/home';

// ----------------------------------------------------------------------

function scrollToTestDiv() {
  const divElement = document.getElementById('AppSection');
  divElement.scrollIntoView({ behavior: 'smooth' });
}

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Products',
    path: '',
    icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
    children: [
      {
        items: [
          { title: 'Swahilies App', path: '', Ide: 'section1' },
          { title: 'Swahilies Pay', path: '', Ide: 'section3' },
          { title: 'Payment API', path: '', Ide: 'section2' },
          { title: 'Virtual Card', path: '', Ide: 'section4' },
          { title: 'Foreign Bank Account', path: '', Ide: 'section4' },
        ],
      },
      // {
      //   subheader: 'Dashboard',
      //   items: [{ title: 'Dashboard', path: PATH_AFTER_LOGIN }],
      // },
    ],
  },
  {
    title: 'About us',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: '/about-us',
  },
  {
    title: 'Contact us',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: 'contact-us',
  },
  {
    title: 'Blog',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: 'https://swahiles-dashboard.vercel.app/',
  },

  {
    title: (
      <Button
        size="small"
        variant="contained"
        component={RouterLink}
        to="/"
        onClick={() => {
          window.open('https://swahiles-dashboard.vercel.app/');
        }}
        endIcon={<Iconify icon={'carbon:arrow-right'} width={13} height={13} />}
      >
        Let's sell without borders
      </Button>
    ),
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: '',
  },
];

export default menuConfig;
