import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Button, Container, Typography, LinearProgress } from '@mui/material';

import useResponsive from '../../hooks/useResponsive';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { MotionInView, varFade } from '../../components/animate';
import API from '../../assets/api.png';
import apiFull from '../../assets/apiFull.png';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 456,
  margin: 'auto',
  overflow: 'hidden',
  paddingBottom: 0,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: '#F6F6F6',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxWidth: '100%',
    paddingBottom: 0,
    alignItems: 'center',
  },
}));

// ----------------------------------------------------------------------

export default function HomeAPI() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';
  const shadow = `-40px 40px 80px ${alpha(isLight ? theme.palette.grey[500] : theme.palette.common.black, 0.48)}`;

  return (
    <Container id="section2" style={{ paddingTop: 50, paddingBottom: 50 }}>
      <ContentStyle>
        {isDesktop && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5} style={{ paddingTop: 110 }}>
              <Box
                sx={{
                  pl: { md: 10 },
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                <MotionInView variants={varFade().inDown} sx={{ color: '#362E93', mb: 2 }}>
                  <Typography variant="h3" style={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>
                    Integrate
                    <Typography
                      component="span"
                      variant="h3"
                      sx={{ color: '#F6A414', fontFamily: 'Open Sans, sans-serif' }}
                    >
                      &nbsp;Swahilies&nbsp;
                    </Typography>
                    API to accept payments in your app or website
                  </Typography>
                </MotionInView>
                <MotionInView variants={varFade().inDown} sx={{ color: '#000', mb: 3 }}>
                  <Typography variant="p" style={{ fontSize: 13 }}>
                    Accept card and mobile money payments from multiple channels (M-pesa, Tigopesa, airtelmoney,
                    Halopesa, T-pesa and Ezy pesa) through a single API
                  </Typography>
                </MotionInView>
                <MotionInView variants={varFade().inDown}>
                  <Button
                    size="small"
                    variant="contained"
                    component={RouterLink}
                    style={{ backgroundColor: '#000' }}
                    to="/"
                    // href="https://swahilies.readme.io/"
                    endIcon={<Iconify icon={'carbon:arrow-right'} width={13} height={13} />}
                    onClick={() => {
                      window.open('https://swahilies.readme.io/');
                    }}
                  >
                    Read our API docs
                  </Button>
                </MotionInView>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
              <Grid container spacing={3} alignItems="self-end">
                <Grid item xs={12}>
                  <MotionInView
                    variants={varFade().inUp}
                    sx={{
                      mb: { xs: 3, md: 0 },
                      mt: 0,
                    }}
                  >
                    <m.div>
                      <Image visibleByDefault alt="rocket" src={apiFull} disabledEffect sx={{ maxWidth: 500 }} />
                    </m.div>
                  </MotionInView>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isDesktop && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5} style={{ paddingTop: 60 }}>
              <Box
                sx={{
                  pl: { md: 10 },
                  textAlign: 'left',
                }}
              >
                <MotionInView
                  variants={varFade().inDown}
                  sx={{ color: '#362E93', mb: 2, paddingLeft: 2, paddingRight: 2 }}
                >
                  <Typography variant="h4" style={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>
                    Integrate
                    <Typography
                      component="span"
                      variant="h4"
                      sx={{ color: '#F6A414', fontFamily: 'Open Sans, sans-serif' }}
                    >
                      &nbsp;Swahilies&nbsp;
                    </Typography>
                    API to accept payments in your app or website
                  </Typography>
                </MotionInView>
                <MotionInView
                  variants={varFade().inDown}
                  sx={{ color: '#000', mb: 3, paddingLeft: 2, paddingRight: 2 }}
                >
                  <Typography variant="p" style={{ fontSize: 13 }}>
                    Accept card and mobile money payments from multiple channels (M-pesa, Tigopesa, airtelmoney,
                    Halopesa, T-pesa and Ezzy pesa) through a single API
                  </Typography>
                </MotionInView>
              </Box>
              <Box
                sx={{
                  pl: { md: 10 },
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                <MotionInView variants={varFade().inDown}>
                  <Button
                    size="small"
                    variant="contained"
                    component={RouterLink}
                    style={{ backgroundColor: '#000' }}
                    to="/"
                    // href="https://swahilies.readme.io/"
                    onClick={() => {
                      window.open('https://swahilies.readme.io/');
                    }}
                    endIcon={<Iconify icon={'carbon:arrow-right'} width={13} height={13} />}
                  >
                    Read our API docs
                  </Button>
                </MotionInView>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12}>
                  <MotionInView
                    variants={varFade().inUp}
                    sx={{
                      mb: 0,
                      mt: 5,
                    }}
                  >
                    <m.div>
                      <Image
                        visibleByDefault
                        alt="rocket"
                        src={API}
                        disabledEffect
                        sx={{ maxWidth: 350, paddingLeft: 3, paddingRight: 3 }}
                      />
                    </m.div>
                  </MotionInView>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ContentStyle>
    </Container>
  );
}
