import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Button, Container, Typography, LinearProgress } from '@mui/material';

import useResponsive from '../../hooks/useResponsive';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { MotionInView, varFade } from '../../components/animate';
import Phone from '../../assets/icon.png';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 456,
  margin: 'auto',
  overflow: 'hidden',
  paddingBottom: 13,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: '#E2F5FE',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxWidth: '100%',
    paddingBottom: 0,
    alignItems: 'center',
  },
}));

// ----------------------------------------------------------------------

export default function HomeAdvertisement() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isLight = theme.palette.mode === 'light';
  const shadow = `-40px 40px 80px ${alpha(isLight ? theme.palette.grey[500] : theme.palette.common.black, 0.48)}`;

  return (
    <Container id="section1" style={{ paddingTop: 50, paddingBottom: 50 }}>
      <ContentStyle>
        {isDesktop && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5} style={{ paddingTop: 110 }}>
              <Box
                sx={{
                  pl: { md: 10 },
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                <MotionInView variants={varFade().inDown} sx={{ color: '#362E93', mb: 2 }}>
                  <Typography variant="h3" style={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>
                    Sell anything, anywhere Accept payments in
                    <Typography
                      component="span"
                      variant="h3"
                      sx={{ color: '#F6A414', fontFamily: 'Open Sans, sans-serif' }}
                    >
                      &nbsp;seconds.
                    </Typography>
                  </Typography>
                </MotionInView>
                <MotionInView variants={varFade().inDown} sx={{ color: '#000', mb: 2 }}>
                  <Typography variant="p" style={{ fontWeight: 'bold' }}>
                    We enable you to easily accept payments from your international customers.
                  </Typography>
                </MotionInView>
                <MotionInView variants={varFade().inDown} sx={{ color: '#000', mb: 3 }}>
                  <Typography variant="p" style={{ fontSize: 13 }}>
                    With our payment feature link, you can easily share a payment link with your customers through 
                    their preferred chanel - whether that's Whatsapp, Facebook, Telegram or any other chanel. 
                  Once your customer pays via the link, we'ill take care of transferring the money from their card to your 
                  bank or mobile money account.
                  </Typography>
                </MotionInView>
                <MotionInView variants={varFade().inDown}>
                  <Button
                    size="small"
                    variant="contained"
                    component={RouterLink}
                    style={{ backgroundColor: '#F6A414' }}
                    to="/"
                    onClick={() => {
                      window.open('https://swahiles-dashboard.vercel.app/');
                    }}
                    endIcon={<Iconify icon={'carbon:arrow-right'} width={13} height={13} />}
                  >
                    Sell without borders
                  </Button>
                </MotionInView>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
              <Grid container spacing={3} alignItems="self-end">
                <Grid item xs={12}>
                  <MotionInView
                    variants={varFade().inUp}
                    sx={{
                      mb: { xs: 3, md: 2 },
                      mt: 0,
                    }}
                  >
                    <m.div animate={{ y: [-20, 0, -20] }} transition={{ duration: 4, repeat: Infinity }}>
                      <Image visibleByDefault alt="rocket" src={Phone} disabledEffect sx={{ maxWidth: 550 }} />
                    </m.div>
                  </MotionInView>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isDesktop && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={5} style={{ paddingTop: 78 }}>
              <Box
                sx={{
                  pl: { md: 10 },
                  textAlign: 'left',
                }}
              >
                <MotionInView
                  variants={varFade().inDown}
                  sx={{ color: '#362E93', fontFamily: 'Open Sans, sans-serif' }}
                >
                  <Typography
                    variant="h1"
                    sx={{ color: '#222326', fontFamily: 'Open Sans, sans-serif' }}
                    style={{
                      fontWeight: 'bold',
                      fontFamily: 'Open Sans, sans-serif',
                      paddingLeft: 12,
                      paddingRight: 2,
                      marginBottom: 10,
                    }}
                  >
                    Sell anything, anywhere Accept payments in
                    <Typography component="span" variant="h1" sx={{ color: '#F6A414' }}>
                      &nbsp;seconds.
                    </Typography>
                  </Typography>
                </MotionInView>
                <MotionInView
                  variants={varFade().inDown}
                  sx={{ color: '#000', paddingLeft: 2, paddingRight: 2, marginBottom: 2 }}
                >
                  <Typography variant="p" style={{ fontWeight: 'bold' }}>
                    We enable you to easily accept payments from your international customers.
                  </Typography>
                </MotionInView>
                <MotionInView
                  variants={varFade().inDown}
                  sx={{ color: '#000', mb: 3, paddingLeft: 2, paddingRight: 2, marginBottom: 2 }}
                >
                  <Typography variant="p" style={{ fontSize: 13 }}>
                    Create and share a payment link with your customers, to accept payments. This can be through
                    whatsapp, whatsapp, instagram, facebook, telegram or any channel. Sit back and relax as we transfer
                    tm from your from your customer's card to your bank account.
                  </Typography>
                </MotionInView>
              </Box>
              <Box
                sx={{
                  pl: { md: 10 },
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                <MotionInView variants={varFade().inDown}>
                  <Button
                    size="small"
                    variant="contained"
                    component={RouterLink}
                    style={{ backgroundColor: '#F6A414' }}
                    to="/"
                    onClick={() => {
                      window.open('https://swahiles-dashboard.vercel.app/');
                    }}
                    endIcon={<Iconify icon={'carbon:arrow-right'} width={13} height={13} />}
                  >
                    Sell without borders
                  </Button>
                </MotionInView>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
              <Grid container spacing={3} alignItems="self-end">
                <Grid item xs={12}>
                  <MotionInView
                    variants={varFade().inUp}
                    sx={{
                      mb: { xs: 3, md: 2 },
                      mt: 0,
                    }}
                  >
                    <m.div animate={{ y: [-20, 0, -20] }} transition={{ duration: 4, repeat: Infinity }}>
                      <Image visibleByDefault alt="rocket" src={Phone} disabledEffect sx={{ maxWidth: 550 }} />
                    </m.div>
                  </MotionInView>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ContentStyle>
    </Container>
  );
}
