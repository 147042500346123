import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useInterval } from 'react-use';

const animation = keyframes`
  0% { opacity: 0; transform: translateY(-100px) skewX(10deg) skewY(10deg) rotateZ(30deg); filter: blur(10px); }
  25% { opacity: 1; transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg); filter: blur(0px); }
  75% { opacity: 1; transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg); filter: blur(1px); }
  100% { opacity: 0; transform: translateY(-100px) skewX(10deg) skewY(10deg) rotateZ(30deg); filter: blur(10px); }
`;

const Wrapper = styled.span`
  display: inline-block;

  span {
    opacity: 0;
    display: inline-block;
    animation-name: ${animation};
    animation-duration: 6s;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
`;

const animeOne = keyframes`
2.5641% {
    opacity: 1;
  }

  3.84615% {
    opacity: 1;
  }

  6.41026% {
    opacity: 0;
  }

  7.69231% {
    opacity: 0;
  }

  10.25641% {
    opacity: 1;
  }

  11.53846% {
    opacity: 1;
  }

  14.10256% {
    opacity: 0;
  }

  15.38462% {
    opacity: 0;
  }

  17.94872% {
    opacity: 1;
  }

  19.23077% {
    opacity: 1;
  }

  21.79487% {
    opacity: 0;
  }

  23.07692% {
    opacity: 0;
  }

  25.64103% {
    opacity: 1;
  }

  26.92308% {
    opacity: 1;
  }

  29.48718% {
    opacity: 0;
  }

  30.76923% {
    opacity: 0;
  }

  33.33333% {
    opacity: 1;
  }

  34.61538% {
    opacity: 1;
  }

  37.17949% {
    opacity: 0;
  }

  38.46154% {
    opacity: 0;
  }

  41.02564% {
    opacity: 1;
  }

  42.30769% {
    opacity: 1;
  }

  44.87179% {
    opacity: 0;
  }

  46.15385% {
    opacity: 0;
  }

  48.71795% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  52.5641% {
    opacity: 0;
  }

  53.84615% {
    opacity: 0;
  }

  56.41026% {
    opacity: 1;
  }

  57.69231% {
    opacity: 1;
  }

  60.25641% {
    opacity: 0;
  }

  61.53846% {
    opacity: 0;
  }

  64.10256% {
    opacity: 1;
  }

  65.38462% {
    opacity: 1;
  }

  67.94872% {
    opacity: 0;
  }

  69.23077% {
    opacity: 0;
  }

  71.79487% {
    opacity: 1;
  }

  73.07692% {
    opacity: 1;
  }

  75.64103% {
    opacity: 0;
  }

  76.92308% {
    opacity: 0;
  }

  79.48718% {
    opacity: 1;
  }

  80.76923% {
    opacity: 1;
  }

  83.33333% {
    opacity: 0;
  }

  84.61538% {
    opacity: 0;
  }

  87.17949% {
    opacity: 1;
  }

  88.46154% {
    opacity: 1;
  }

  91.02564% {
    opacity: 0;
  }

  92.30769% {
    opacity: 0;
  }

  94.87179% {
    opacity: 1;
  }

  96.15385% {
    opacity: 1;
  }

  98.71795% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }

  0% {
  }

  7.69221% {
  }

  7.69231% {
  }

  15.38452% {
  }
`;

const animeTwo = keyframes`
2.5641% {
    opacity: 1;
  }

  3.84615% {
    opacity: 1;
  }

  6.41026% {
    opacity: 0;
  }

  7.69231% {
    opacity: 0;
  }

  10.25641% {
    opacity: 1;
  }

  11.53846% {
    opacity: 1;
  }

  14.10256% {
    opacity: 0;
  }

  15.38462% {
    opacity: 0;
  }

  17.94872% {
    opacity: 1;
  }

  19.23077% {
    opacity: 1;
  }

  21.79487% {
    opacity: 0;
  }

  23.07692% {
    opacity: 0;
  }

  25.64103% {
    opacity: 1;
  }

  26.92308% {
    opacity: 1;
  }

  29.48718% {
    opacity: 0;
  }

  30.76923% {
    opacity: 0;
  }

  33.33333% {
    opacity: 1;
  }

  34.61538% {
    opacity: 1;
  }

  37.17949% {
    opacity: 0;
  }

  38.46154% {
    opacity: 0;
  }

  41.02564% {
    opacity: 1;
  }

  42.30769% {
    opacity: 1;
  }

  44.87179% {
    opacity: 0;
  }

  46.15385% {
    opacity: 0;
  }

  48.71795% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  52.5641% {
    opacity: 0;
  }

  53.84615% {
    opacity: 0;
  }

  56.41026% {
    opacity: 1;
  }

  57.69231% {
    opacity: 1;
  }

  60.25641% {
    opacity: 0;
  }

  61.53846% {
    opacity: 0;
  }

  64.10256% {
    opacity: 1;
  }

  65.38462% {
    opacity: 1;
  }

  67.94872% {
    opacity: 0;
  }

  69.23077% {
    opacity: 0;
  }

  71.79487% {
    opacity: 1;
  }

  73.07692% {
    opacity: 1;
  }

  75.64103% {
    opacity: 0;
  }

  76.92308% {
    opacity: 0;
  }

  79.48718% {
    opacity: 1;
  }

  80.76923% {
    opacity: 1;
  }

  83.33333% {
    opacity: 0;
  }

  84.61538% {
    opacity: 0;
  }

  87.17949% {
    opacity: 1;
  }

  88.46154% {
    opacity: 1;
  }

  91.02564% {
    opacity: 0;
  }

  92.30769% {
    opacity: 0;
  }

  94.87179% {
    opacity: 1;
  }

  96.15385% {
    opacity: 1;
  }

  98.71795% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }

  0% {
  }

  7.69221% {
  }
`;

const AnimeText = styled.span`
  & :before,
  :after {
    position: absolute;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation-duration: 39s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
  &:before {
    animation-name: ${animeOne};
    color: yellow;
  }
  &:after {
    animation-name: ${animeTwo};
    animation-delay: 1.5s;
    color: black;
  }
`;

export default function TextAnimation() {
  const businessArray = 'businesses'.split('');
  const creatorArray = 'Creators'.split('');
  const startupArray = 'Startups'.split('');
  const [items, setItems] = useState(businessArray);
  const [colorz, setColor] = useState('#6525EE');
  const [count, setCount] = useState(0);
  const [play, setPlay] = useState(false);

  useInterval(
    () => {
      setItems(businessArray);
      setColor('#6525EE');
      setCount(count + 1);

      if (count === 1) {
        setCount(0);
        setItems(startupArray);
        setColor('#0EA101');
      }
    },
    play ? 2900 : null
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setItems(startupArray);
      setColor('#0EA101');
      setPlay(true);
    }, 2900);

    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimeText>
      {items.map((item, index) => (
        <span style={{ color: colorz, fontFamily: 'Open Sans, sans-serif' }} key={index}>
          {item}
        </span>
      ))}
    </AnimeText>
  );
}
