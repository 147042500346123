import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Company',
    children: [
      { name: 'About us', href: 'about-us' },
      { name: 'Contact us', href: 'contact-us' },
      {
        name: 'Blog',
        href: 'https://medium.com/@jhaulay/jinsi-tulivyoanzisha-swahilies-app-na-jinsi-tunavyoendelea-na-swahilies-pay-585c9b60e79e',
      },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms of service', href: '#' },
      { name: 'Privacy policy', href: '#' },
      { name: 'Cookies policy', href: '#' },
    ],
  },
  {
    headline: 'Social media',
    children: [
      { name: 'Instagram', href: 'https://www.instagram.com/swahiliesapp/' },
      { name: 'Twitter', href: 'https://twitter.com/swahiliespay' },
      { name: 'Facebook', href: '#' },
      { name: 'Youtube', href: 'https://www.youtube.com/channel/UCwvyfydQr2uh1FCR4QWPjqw' },
    ],
  },
];

const LINKTWO = [
  {
    headline: 'Products',
    children: [
      { name: 'Payment Links', href: 'section3' },
      { name: 'Layby (Kidogo kidogo)', href: 'section1' },
      { name: 'Payment API', href: 'section2' },
      { name: 'Virtual & physical cards', href: 'section4' },
      { name: 'Foreign bank accounts', href: 'section4' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#E5E5E5',
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' }, paddingBottom: 8 }}
        >
          <Grid item xs={12} md={7}>
            {isDesktop && (
              <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
                <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />

                {LINKTWO.map((list) => (
                  <Stack key={list.headline} spacing={3}>
                    <Typography component="p" style={{ color: '#000', width: 180 }} variant="overline">
                      {list.headline}
                    </Typography>
                    {list.children.map((link) => (
                      <ScrollLink
                        activeClass="active"
                        to={link.href}
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        style={{ color: '#717173' }}
                      >
                        {link.name}
                      </ScrollLink>
                    ))}
                  </Stack>
                ))}

                {LINKS.map((list) => (
                  <Stack key={list.headline} spacing={3}>
                    <Typography component="p" style={{ color: '#000', width: 180 }} variant="overline">
                      {list.headline}
                    </Typography>
                    {list.children.map((link) => (
                      <Link
                        to="/"
                        key={link.name}
                        color="#717173"
                        onClick={() => {
                          window.open(link.href);
                        }}
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                ))}
              </Stack>
            )}
            {!isDesktop && (
              <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
                <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                {LINKS.map((list) => (
                  <Stack key={list.headline} spacing={2}>
                    <Typography component="p" style={{ color: '#000' }} variant="overline">
                      {list.headline}
                    </Typography>
                    {list.children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="#717173"
                        onClick={() => {
                          window.open(link.href);
                        }}
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                ))}
              </Stack>
            )}
          </Grid>
        </Grid>

        <Divider color="#F6A414" />
        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
            color: '#717173',
          }}
        >
          © 2021 Swahilies. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
